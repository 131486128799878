import React, { useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { useLiteraryWorksPageContext } from '../../context';
import ModalLiteraryWork from '../../../../pages/commons/components/ModalLiteraryWork';
import Work from '../Work';
import AddWorkCard from '../AddWorkCard';
import { WorksContainer, OpenBookIcon, AddWorkButton, PlaceholderContainer, PlaceholderText } from './styles';
const statusAndPlaceholders = {
    [RequestStatus.Loading]: 'Carregando obras...',
    [RequestStatus.Failed]: 'Falha ao carregar obras literárias'
};
const Works = ({ literaryWorks, requestStatus }) => {
    const { isReadOnly, refetchLiteraryWorks } = useLiteraryWorksPageContext();
    const [showModalLiteraryWork, setShowModalLiteraryWork] = useState(false);
    const noOneLiteraryWork = !literaryWorks || literaryWorks.length === 0;
    const placeholder = requestStatus in statusAndPlaceholders;
    const handleOpenModal = () => {
        setShowModalLiteraryWork(true);
    };
    return (React.createElement(React.Fragment, null,
        placeholder && (React.createElement(PlaceholderContainer, null,
            React.createElement(PlaceholderText, null, statusAndPlaceholders[requestStatus]))),
        !placeholder && noOneLiteraryWork && (React.createElement(PlaceholderContainer, null,
            React.createElement(OpenBookIcon, null),
            React.createElement(PlaceholderText, null, "Nenhuma obra liter\u00E1ria adicionada"),
            isReadOnly ? null : (React.createElement(AddWorkButton, { onClick: handleOpenModal }, "Adicionar Obra")))),
        !placeholder && !noOneLiteraryWork && (React.createElement(WorksContainer, null,
            literaryWorks.map((literaryWork) => (React.createElement(Work, { key: literaryWork.id, literaryWork: literaryWork }))),
            isReadOnly ? null : (React.createElement(AddWorkCard, { handleOpenModalNewLiteraryWork: handleOpenModal })))),
        !placeholder && showModalLiteraryWork && (React.createElement(ModalLiteraryWork, { show: showModalLiteraryWork, onHide: () => setShowModalLiteraryWork(false), onSaved: refetchLiteraryWorks }))));
};
export default Works;
