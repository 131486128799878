import styled from 'styled-components';
import { QuestionCardForSetting } from '@campus/content-items';
import { Box } from '../commons/styles/basics';
import { commonCardStyles, commonCommentBoxStyles, commonEditorFontStyles, commonOptionsBoxStyles } from '../commons/styles/questionRelated';
export const Container = styled(Box) ``;
export const Question = styled(QuestionCardForSetting) `
  ${commonCardStyles}

  &.answered
    .ck.ck-editor__editable.ck-blurred
    .ck-widget.ck-widget_selected {
    outline: none;
  }

  .question-setting__item-list .ck.ck-editor__editable_inline {
    ${commonEditorFontStyles}
  }

  .question-setting__options-box {
    ${({ theme }) => commonOptionsBoxStyles(theme)}
  }

  .question-setting__comment-box {
    ${commonCommentBoxStyles}
  }
`;
