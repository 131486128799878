import React from 'react';
import { useLiteraryWorkPageContext } from '../../../context';
import { showErrorsUsingToast } from '../../../commons/errors';
import { useVideoManager } from '../hooks/useVideoManager';
import { ContainerMessage, Message, OpenBookIcon } from './styles';
import { MainVideoReadOnly } from './MainVideo';
import { VideoListReadOnly } from './VideoList';
const ReadOnlyVideoList = ({ videoAnalisysItems }) => {
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { items, mainVideo, updateMainVideo } = useVideoManager({
        items: videoAnalisysItems,
        literaryWorkId,
        onError: showErrorsUsingToast
    });
    if (!items || items.length === 0 || !mainVideo) {
        return (React.createElement(ContainerMessage, null,
            React.createElement(OpenBookIcon, null),
            React.createElement(Message, null, "Nenhum v\u00EDdeo adicionado")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MainVideoReadOnly, { video: mainVideo }),
        React.createElement(VideoListReadOnly, { videos: items, onChangeMainVideo: updateMainVideo })));
};
export default ReadOnlyVideoList;
