import React, { useCallback, useRef } from 'react';
import { useExternalClick } from '@campus/commons';
import { VideoProcessing } from '@campus/components';
import MenuVideo from '../../MenuVideo';
import { Container, ContainerTitle, DatePublish, ThumbnailContainer, StyledButton, Title } from './styles';
import Iframe from '../../Iframe';
const VideoListItem = ({ item, onUpdate, onChangeMainVideo, onDelete }) => {
    const menuRef = useRef(null);
    const containerRef = useRef(null);
    const handleExternalClick = useCallback(() => {
        menuRef.current?.hideDialog();
    }, []);
    useExternalClick(containerRef, handleExternalClick);
    const handleDeleteVideo = async () => {
        await onDelete(item.id);
    };
    return (React.createElement(Container, { ref: containerRef },
        React.createElement(ThumbnailContainer, null,
            item.isProcessed && (React.createElement(React.Fragment, null,
                React.createElement(Iframe, { url: item.url, title: item.title, controls: false }),
                React.createElement(StyledButton, { onClick: () => onChangeMainVideo(item) }))),
            !item.isProcessed && React.createElement(VideoProcessing, { small: true })),
        React.createElement(ContainerTitle, null,
            React.createElement(MenuVideo, { ref: menuRef, onClickDelete: handleDeleteVideo, margin: 0 }),
            React.createElement(Title, { value: item.title, key: item.id, onChange: (titles) => {
                    item.title = titles;
                    onUpdate(item);
                } })),
        React.createElement(DatePublish, null, item.publishDate)));
};
export default VideoListItem;
