import { useCallback, useState } from 'react';
export function usePagination(props) {
    const [pageIndex, setPageIndex] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const incrementPageIndex = useCallback(() => {
        if (!hasMorePages)
            return;
        setPageIndex((currentIndex) => currentIndex + 1);
    }, [hasMorePages]);
    const decrementPageIndex = useCallback(() => {
        setPageIndex((currentIndex) => currentIndex > 0 ? currentIndex - 1 : currentIndex);
    }, []);
    const definePageIndex = useCallback((newIndex) => {
        if (newIndex >= 0 && newIndex < numberOfPages) {
            setPageIndex(newIndex);
        }
    }, [numberOfPages]);
    const updateInfosFromResponse = useCallback((response) => {
        setHasMorePages((currentValue) => {
            return response?.data ? response.data.hasNextPage : currentValue;
        });
        setNumberOfPages((currentValue) => {
            return response?.data
                ? Math.ceil(response.data.totalCount / props.pageSize)
                : currentValue;
        });
    }, [props.pageSize]);
    return {
        pageIndex,
        hasMorePages,
        numberOfPages,
        incrementPageIndex,
        decrementPageIndex,
        setPageIndex: definePageIndex,
        updateInfosFromResponse
    };
}
