import React, { useCallback, useRef } from 'react';
import { useExternalClick } from '@campus/commons';
import { Container, ContainerTitle, DatePublish, ThumbnailContainer, StyledButton, Title, ContainerData, UserPhoto, ContainerInfo, UserName } from './styles';
import Iframe from '../../Iframe';
const VideoListItemReadOnly = ({ item, onChangeMainVideo }) => {
    const menuRef = useRef(null);
    const containerRef = useRef(null);
    const handleExternalClick = useCallback(() => {
        menuRef.current?.hideDialog();
    }, []);
    useExternalClick(containerRef, handleExternalClick);
    return (React.createElement(Container, { ref: containerRef },
        React.createElement(ThumbnailContainer, null,
            React.createElement(Iframe, { url: item.url, title: item.title, controls: false }),
            React.createElement(StyledButton, { onClick: () => onChangeMainVideo(item) })),
        React.createElement(ContainerTitle, null,
            React.createElement(Title, { value: item.title, key: item.id, disabled: true })),
        React.createElement(ContainerData, null,
            React.createElement(UserPhoto, { src: item.user.imageUrl }),
            React.createElement(ContainerInfo, null,
                React.createElement(UserName, null, item.user.name),
                React.createElement(DatePublish, null, item.publishDate)))));
};
export default VideoListItemReadOnly;
