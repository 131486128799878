import React, { useRef, useCallback, useState } from 'react';
import { useExternalClick } from '@campus/commons';
import { ConfirmationModal, VideoProcessing } from '@campus/components';
import Iframe from '../../components/Iframe';
import MenuVideo from '../MenuVideo';
import DescriptionDeleteVideo from './DescriptionDeleteVideo';
import { Title, ContainerTitle, ContainerVideo, Container, DatePublish } from './styles';
const MainVideo = ({ video, onUpdate, onDelete }) => {
    const menuRef = useRef(null);
    const containerRef = useRef(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleExternalClick = useCallback(() => {
        menuRef.current?.hideDialog();
    }, []);
    useExternalClick(containerRef, handleExternalClick);
    const handleDeleteVideo = useCallback(() => {
        setShowDeleteModal(true);
    }, []);
    const handleSubmitDelete = async () => {
        await onDelete(video.id);
    };
    return (React.createElement(Container, { ref: containerRef },
        React.createElement(ContainerVideo, null,
            video.isProcessed && (React.createElement(Iframe, { url: video.url, title: video.title, controls: true })),
            !video.isProcessed && React.createElement(VideoProcessing, { small: false })),
        React.createElement(ContainerTitle, null,
            React.createElement(MenuVideo, { ref: menuRef, onClickDelete: handleDeleteVideo, margin: 6 }),
            React.createElement(Title, { value: video.title, onChange: (titles) => {
                    video.title = titles;
                    onUpdate(video);
                } })),
        React.createElement(DatePublish, null, video.publishDate),
        showDeleteModal && (React.createElement(ConfirmationModal, { title: "Apagar v\u00EDdeo", description: React.createElement(DescriptionDeleteVideo, null), show: showDeleteModal, onHide: () => {
                setShowDeleteModal(false);
            }, onSubmit: handleSubmitDelete }))));
};
export default MainVideo;
