import styled from 'styled-components';
import { CampusIcon } from '..';
const twoLineLayoutMediaQuery = '(max-width: 400px)';
export const Container = styled.div `
  display: grid;
  row-gap: 15px;
  column-gap: 10px;
  align-items: start;
  justify-content: space-between;
  grid-template-columns: auto 1fr auto;

  @media screen and ${twoLineLayoutMediaQuery} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
  }
`;
export const PageNumbersContainer = styled.div `
  display: grid;
  gap: 5px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(30px, max-content));

  @media screen and ${twoLineLayoutMediaQuery} {
    grid-column: 1 / 3;
  }
`;
const BaseButton = styled.button `
  padding: 10px 12px;

  font-size: 14px;
  font-weight: 600;

  line-height: 20px;

  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.colors.gray500};

  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray200};

  &:hover {
    border-color: ${(props) => props.theme.colors.gray500}50;
    background: ${(props) => props.theme.colors.gray500}28;
  }

  &:focus-visible {
    outline: 1px solid ${(props) => props.theme.colors.gray500};
  }

  &[disabled],
  &[disabled]:hover {
    background: ${(props) => props.theme.colors.gray300}3c;
    border-color: ${(props) => props.theme.colors.gray300}28;
  }
`;
export const PageNumber = styled(BaseButton) `
  ${(props) => {
    if (!props.$isSelected)
        return '';
    return `
      background: ${props.theme.colors.gray500}3c;
      border-color: ${props.theme.colors.gray500}50;
    `;
}}
`;
export const PreviousPageButton = styled(BaseButton) `
  @media screen and ${twoLineLayoutMediaQuery} {
    grid-row: 1 / 2;
    grid-column: 1 / 2;

    justify-self: start;
  }
`;
export const NextPageButton = styled(BaseButton) `
  @media screen and ${twoLineLayoutMediaQuery} {
    grid-row: 1 / 2;
    grid-column: 2 / 3;

    justify-self: end;
  }
`;
export const ArrowRightIcon = styled(CampusIcon).attrs({
    name: 'miniArrowRight'
}) `
  display: block;

  height: 14px;

  color: ${(props) => props.theme.colors.gray500};
`;
export const ArrowLeftIcon = styled(ArrowRightIcon) `
  rotate: 180deg;
`;
export const ButtonText = styled.p `
  margin-bottom: 0;
`;
