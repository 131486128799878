import styled from 'styled-components';
import { classNames } from './classNames';
export const Container = styled.div `
  display: flex;
  row-gap: 9px;
  flex-direction: column;

  & .${classNames.workMenu} {
    display: none;
  }

  &:hover .${classNames.workMenu} {
    display: initial;
  }
`;
export const NotPublishedMessageContainer = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;

  padding: 5px 11px;

  background: white;

  border-radius: 31px;
`;
export const NotPublishedMessageText = styled.p `
  margin-bottom: 0;

  text-align: center;

  white-space: nowrap;

  font-weight: 700;
  font-size: 14.432px;

  line-height: 100%;

  color: ${(props) => props.theme.colors.CSA.darkBlue};
`;
export const Title = styled.p `
  margin-bottom: 0;

  font-size: 14px;
  font-weight: 700;

  line-height: 100%;

  color: ${(props) => props.theme.colors.gray2};
`;
