import React from 'react';
import { Container, Image, NoImageText } from './styles';
const WorkCoverImage = (props) => {
    const { children, literaryWork, onClick } = props;
    const { coverImageUrl, published } = literaryWork;
    return (React.createElement(Container, { "$hasImage": !!coverImageUrl, ...(onClick ? { onClick, $isClickable: true } : {}) },
        coverImageUrl ? (React.createElement(Image, { src: coverImageUrl, "$workIsPublished": published })) : (React.createElement(NoImageText, null, "Esta obra n\u00E3o possui capa...")),
        children));
};
export default WorkCoverImage;
