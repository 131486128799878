import React, { useRef, useImperativeHandle, useEffect } from 'react';
import RequiredTag from '../RequiredTag';
import Check from '../Check';
import FormFeedback from '../FormFeedback';
import { StyledCampusIcon, StyledCheck, StyledCheckLabel } from './styles';
const Checkbox = React.forwardRef((checkboxProps, inRef) => {
    const ref = useRef(null);
    useImperativeHandle(inRef, () => ref.current, [ref]);
    const { name, label, errorMessage, isError, required, checked, indeterminate, icon, color, marginLeft, ...props } = checkboxProps;
    useEffect(() => {
        ref.current.indeterminate = indeterminate;
    }, [ref, indeterminate]);
    return (React.createElement(StyledCheck, null,
        React.createElement(Check, { ...props, ref: ref, id: name, name: name, type: "checkbox", required: required, isInvalid: isError, checked: checked }),
        icon && (React.createElement(StyledCampusIcon, { name: icon, color: color, size: 20, "$marginLeft": marginLeft })),
        label && (React.createElement(StyledCheckLabel, { htmlFor: name, "$marginLeft": marginLeft },
            label,
            " ",
            required ? React.createElement(RequiredTag, null, "\u00A0*") : null)),
        isError && errorMessage && React.createElement(FormFeedback, null, errorMessage)));
});
export default Checkbox;
