import styled from 'styled-components';
import { QuestionCardForAnswer } from '@campus/content-items';
import { Box } from '../commons/styles/basics';
import { commonCardStyles, commonCommentBoxStyles, commonEditorFontStyles, commonOptionsBoxStyles } from '../commons/styles/questionRelated';
export const Container = styled(Box) ``;
export const Question = styled(QuestionCardForAnswer) `
  ${commonCardStyles}

  height: auto;

  .card-answer__item-list .ck.ck-editor__editable_inline,
  .card-answer__open-question-box .ck.ck-editor__editable_inline {
    ${commonEditorFontStyles}
  }

  .card-answer__options-box {
    ${({ theme }) => commonOptionsBoxStyles(theme)}
  }

  &.answered .card-answer__options-box {
    .question-option.correct.correctly-selected input[type='radio'] {
      border-color: ${(props) => props.theme.colors.green3};
    }

    .question-option.correct.correctly-selected input[type='checkbox'] {
      background: white;
      border: 5px solid ${(props) => props.theme.colors.green3};
    }

    .question-option.correct:not(.correctly-selected) {
      input[type='radio'],
      input[type='checkbox'] {
        border: none;
        background: ${(props) => props.theme.colors.green3};
      }
    }

    .question-option.wrongly-selected {
      input[type='radio'],
      input[type='checkbox'] {
        background: white;
        background-size: 12px;
        border: 5px solid ${(props) => props.theme.colors.error};
      }
    }
  }

  .card-answer__open-question-box {
    display: block;
    padding: 10px 14px 14px 14px;
  }

  &.answered .card-answer__open-question-box {
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: ${(props) => props.theme.colors.gray50};
  }

  &.answered .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected {
    outline: none;
  }

  &.answered
    .card-answer__open-question-editor
    .ck.ck-content.ck-editor__editable {
    min-height: 0;
  }

  &:not(.answered) .card-answer__open-question-editor {
    .ck.ck-content.ck-editor__editable {
      padding: 10px 12px;
      border: 1px solid ${(props) => props.theme.colors.gray200} !important;
    }

    .ck.ck-content.ck-editor__editable.ck-rounded-corners {
      border-radius: 6px;
    }
  }

  .card-answer__attachment-upload {
    margin-top: 14px;
    border-color: ${(props) => props.theme.colors.gray200};

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .card-answer__comment-box {
    ${commonCommentBoxStyles}
  }

  .card-answer__feedback-box {
    ${commonCommentBoxStyles}
  }
`;
export const OpenQuestionTitle = styled.h3 `
  margin-bottom: 5px;

  font-size: 16px;
  font-weight: 700;

  line-height: 24px;

  color: ${(props) => props.theme.colors.blue1};
`;
export const AnswerQuestionButtonContainer = styled.div `
  padding: 10px 14px 14px 14px;

  border-top: 1px solid ${(props) => props.theme.colors.gray200};
`;
