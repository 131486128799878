import styled from 'styled-components';
export const Container = styled.div `
  grid-column: full-width;

  display: grid;
  grid-template-columns: inherit;

  & > * {
    grid-column: content;
  }

  background: #27dd8f;
  padding: 44px 0 11px 0;
`;
export const Title = styled.h1 `
  margin-bottom: 0;

  color: white;

  font-size: 36px;
  font-style: normal;
  font-weight: 700;

  line-height: 110%;
`;
