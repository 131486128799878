import React, { useRef } from 'react';
import useDragAndDrop from './hooks/useDragAndDrop';
import { Container, DescriptionImage, DivImage, DivDropImage, LabelUpload } from './styles';
import CampusIcon from '../CampusIcon';
const DropImage = ({ imageUrl, onChange, label }) => {
    const { handleDragOver, handleDragLeave, handleDrop, isDraggingOver } = useDragAndDrop({ onChange });
    const fileInputRef = useRef(null);
    const handleSelectFile = () => {
        if (imageUrl) {
            onChange(null);
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }
    };
    const handleFileInputChange = (e) => {
        if (e.target.files) {
            onChange(e.target.files);
        }
    };
    return (React.createElement(Container, null,
        !imageUrl && (React.createElement(DivDropImage, { onDragOver: handleDragOver, onDragLeave: handleDragLeave, onDrop: handleDrop, onClick: handleSelectFile, "$isDraggingOver": isDraggingOver },
            React.createElement(CampusIcon, { size: 53, name: "upload" }),
            React.createElement(LabelUpload, null, label),
            React.createElement("input", { type: "file", ref: fileInputRef, style: { display: 'none' }, onChange: handleFileInputChange }))),
        imageUrl && React.createElement(DivImage, { onClick: handleSelectFile, "$image": imageUrl }),
        React.createElement(DescriptionImage, null, "Os formatos de imagens permitidos s\u00E3o JPG, GIF e PNG. M\u00E1ximo de 5Mb.")));
};
export default DropImage;
