import { Upload, UploadProvider } from '@campus/commons';
import { uploadForQuestion } from '../../../../../services';
import { onUploadError } from '../../../commons/errors';
export function useUploadForQuestion({ questionId, literaryWorkId }) {
    function createUploadInstance(type, uploadProvider) {
        return new Upload('question', {
            type,
            variantId: questionId,
            provider: uploadProvider ?? UploadProvider.Azure,
            howToUpload(params) {
                return uploadForQuestion({
                    ...params,
                    literaryWorkId,
                    questionId,
                    whatToUpload: params.type
                });
            },
            onError(error) {
                onUploadError(error);
                throw error.error;
            }
        });
    }
    return { createUploadInstance };
}
