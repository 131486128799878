import styled from 'styled-components';
import Card from '../Card';
export const Container = styled(Card) `
  position: relative;

  background: ${(props) => (props.$hasImage ? 'white' : 'transparent')};

  ${(props) => (props.$isClickable ? 'cursor: pointer;' : '')}
`;
export const Image = styled.img `
  width: 100%;
  height: 100%;

  object-fit: cover;

  border-radius: inherit;

  ${(props) => (props.$workIsPublished ? '' : 'opacity: 0.4;')}
`;
export const NoImageText = styled.p `
  margin: 10px;

  font-size: 14px;
  font-weight: 500;
  font-style: italic;

  line-height: 100%;

  color: ${(props) => props.theme.colors.gray200};
`;
