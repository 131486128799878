import { toast } from '@campus/components';
export function showErrorsUsingToast(errorOrErrors) {
    if (Array.isArray(errorOrErrors)) {
        for (const message of errorOrErrors) {
            toast.error(message);
        }
    }
    else {
        toast.error(errorOrErrors);
    }
}
export function onUploadError(error) {
    if (error.validationErrors?.length) {
        for (const message of error.validationErrors) {
            toast.error(message);
        }
    }
    else {
        toast.error(error.message);
    }
}
