import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import { Placeholder } from '../../../commons/components';
export const WorksContainer = styled.div `
  margin-top: 30px;

  display: grid;
  row-gap: 34px;
  column-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(auto, 142px));
`;
export const PlaceholderContainer = styled(Placeholder.Container) `
  margin-top: 71px;
  min-height: 200px;
  flex-direction: column;
`;
export const PlaceholderText = styled(Placeholder.Text) ``;
export const OpenBookIcon = styled(CampusIcon).attrs({
    name: 'noCurriculumComponentsSelected'
}) `
  display: block;

  fill: none;

  stroke-width: 2px;

  color: ${(props) => props.theme.colors.blue1};
`;
export const AddWorkButton = styled.button `
  margin-top: 16px;

  height: 24px;

  padding: 10px 8px;

  color: white;

  font-size: 12px;
  font-weight: 600;

  line-height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 3px;

  border: 1px solid ${(props) => props.theme.colors.blue1};
  background: ${(props) => props.theme.colors.blue1};

  &:hover {
    opacity: 0.8;
    background: ${(props) => props.theme.colors.blue1};
  }
`;
