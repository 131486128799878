import AutoSave from '@ckeditor/ckeditor5-autosave/src/autosave.js';
import BalloonBlockEditor from '@ckeditor/ckeditor5-editor-balloon/src/ballooneditor.js';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import BlockToolbar from '@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import MathType from '@wiris/mathtype-ckeditor5/src/plugin';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js';
import {
  SpecialCharacters,
  SpecialCharactersEssentials,
  SpecialCharactersMathematical,
  SpecialCharactersCurrency,
  SpecialCharactersText,
  SpecialCharactersLatin,
  SpecialCharactersArrows
} from '@ckeditor/ckeditor5-special-characters';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript.js';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';

class Editor extends BalloonBlockEditor {}

// Plugins to include in the build.
Editor.builtinPlugins = [
  BlockToolbar,
  Bold,
  Essentials,
  Heading,
  Highlight,
  Image,
  ImageResize,
  ImageUpload,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MathType,
  Paragraph,
  RemoveFormat,
  SpecialCharacters,
  SpecialCharactersEssentials,
  SpecialCharactersMathematical,
  SpecialCharactersCurrency,
  SpecialCharactersText,
  SpecialCharactersLatin,
  SpecialCharactersArrows,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  Underline,
  BlockQuote
];

const items = [
  'heading',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'removeFormat',
  '|',
  'subscript',
  'superscript',
  'MathType',
  'link',
  'blockQuote',
  '|',
  'imageUpload',
  '|',
  'imageresize',
  '|',
  'imageStyle:alignLeft',
  'imageStyle:alignRight',
  'imageStyle:alignBlockLeft',
  'imageStyle:alignCenter',
  'imageStyle:alignBlockRight',
  '|',
  'highlight:yellowMarker',
  'highlight:greenMarker',
  'highlight:pinkMarker',
  'highlight:greenPen',
  'highlight:redPen',
  'removeHighlight',
  '|',
  'specialCharacters',
  'numberedList',
  'bulletedList',
  'indent',
  'outdent',
  '|',
  'insertTable'
];

// Editor configuration.
Editor.defaultConfig = {
  language: 'pt-br',
  toolbar: { items: items },
  blockToolbar: items
};

// --------------------------------------------------

class CustomClassicEditor extends ClassicEditor {}

// Plugins to include in the build.
CustomClassicEditor.builtinPlugins = [
  AutoSave,
  Bold,
  Essentials,
  FontBackgroundColor,
  Image,
  ImageCaption,
  ImageUpload,
  Italic,
  Link,
  Paragraph,
  PasteFromOffice,
  Underline
];

// Editor configuration.
CustomClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      'bold',
      'italic',
      'underline',
      'fontBackgroundColor',
      '|',
      'link',
      'imageUpload'
    ]
  },
  language: 'pt-br'
};

export default { Editor, CustomClassicEditor };
