import { Upload, UploadProvider } from '@campus/commons';
import { uploadForQuestionAnswer } from '../../../../../services';
import { onUploadError } from '../../../commons/errors';
export function useUploadForQuestionAnswer({ studentId, questionId, literaryWorkId }) {
    function createUploadInstance(type, uploadProvider) {
        return new Upload('question', {
            type,
            variantId: questionId,
            provider: uploadProvider ?? UploadProvider.Azure,
            howToUpload(params) {
                return uploadForQuestionAnswer({
                    ...params,
                    literaryWorkId,
                    questionId,
                    studentId,
                    whatToUpload: params.type
                });
            },
            onError(error) {
                onUploadError(error);
                throw error.error;
            }
        });
    }
    return { createUploadInstance };
}
