import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import Card from '../../../commons/components/Card';
export const Container = styled(Card) `
  cursor: pointer;

  padding: 14.432px;

  display: flex;
  row-gap: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Text = styled.p `
  margin-bottom: 0;

  text-align: center;

  font-size: 14.432px;
  font-weight: 700;

  line-height: 100%;

  color: ${(props) => props.theme.colors.gray200};
`;
export const Icon = styled(CampusIcon).attrs({
    name: 'circleWithPlusSign'
}) `
  color: ${(props) => props.theme.colors.gray100};
`;
export const ButtonNewLiteraryWork = styled.button `
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  height: max-content;
  display: flex;
`;
