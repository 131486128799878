import React, { useState } from 'react';
import { Container, PageNumber, PageNumbersContainer, ButtonText, PreviousPageButton, NextPageButton, ArrowLeftIcon, ArrowRightIcon } from './styles';
const PageController = (props) => {
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    function pageNumberClickHandler(pageIndex) {
        return async () => {
            setCurrentPageIndex(pageIndex);
            await props.onPageChange(pageIndex);
        };
    }
    async function goToPreviousPageButtonClicked() {
        const newPageIndex = currentPageIndex - 1;
        setCurrentPageIndex(newPageIndex);
        await props.onPageChange(newPageIndex);
    }
    async function goToNextPageButtonClicked() {
        const newPageIndex = currentPageIndex + 1;
        setCurrentPageIndex(newPageIndex);
        await props.onPageChange(newPageIndex);
    }
    function renderPageNumbers() {
        const children = Array.from({ length: props.numberOfPages });
        for (let i = 0; i < props.numberOfPages; ++i) {
            children[i] = (React.createElement(PageNumber, { key: i, onClick: pageNumberClickHandler(i), disabled: props.numberOfPages === 1, "$isSelected": currentPageIndex === i }, i + 1));
        }
        return children;
    }
    return (React.createElement(Container, { className: props.className },
        React.createElement(PreviousPageButton, { onClick: goToPreviousPageButtonClicked, disabled: currentPageIndex === 0 },
            React.createElement(ArrowLeftIcon, null),
            React.createElement(ButtonText, null, "Anterior")),
        React.createElement(PageNumbersContainer, null, renderPageNumbers()),
        React.createElement(NextPageButton, { onClick: goToNextPageButtonClicked, disabled: currentPageIndex === props.numberOfPages - 1 },
            React.createElement(ButtonText, null, "Pr\u00F3xima"),
            React.createElement(ArrowRightIcon, null))));
};
export default PageController;
