import styled from 'styled-components';
import { CreateQuestionModal, EditQuestionModal } from '@campus/content-items';
import { commonOptionsBoxStyles } from '../commons/styles/questionRelated';
const commonStyles = (theme) => `
  .question-editable__header {
    padding: 16px 16px 7px 16px;
  }

  .question-editable__content {
    padding: 0 16px 16px 16px;
  }

  .question-editable__options-box {
    padding: 14px 16px;

    ${commonOptionsBoxStyles(theme)}
  }

  .question-editable__open-question-box {
    display: block;
    padding: 10px 16px 16px 16px;
  }

  .question-editable__comment-box {
    padding: 10px 16px 16px 16px;
  }
`;
export const CreateModal = styled(CreateQuestionModal) `
  ${(props) => commonStyles(props.theme)}
`;
export const EditModal = styled(EditQuestionModal) `
  ${(props) => commonStyles(props.theme)}
`;
