import React from 'react';
import { ContentItemType } from '@campus/content-items';
import { isUploadComplete } from '../../../../../services';
import { useLiteraryWorkPageContext } from '../../../context';
import { showErrorsUsingToast } from '../../../commons/errors';
import { useUploadForLiteraryWork } from '../../../hooks';
import { useItemsManager } from '../hooks';
import { AddItemButton, EditableList, Message, Title, WithItemsContainer, WithoutItemsContainer } from './styles';
const EditableItemList = ({ presentationItems }) => {
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { createUploadInstance } = useUploadForLiteraryWork({
        literaryWorkId
    });
    const { items, addItem, reorderItem, removeItem, updateItem } = useItemsManager({
        items: presentationItems,
        literaryWorkId,
        onError: showErrorsUsingToast
    });
    function renderAddItemButton() {
        return (React.createElement(AddItemButton, { hiddenOptions: [ContentItemType.QUESTION], handleItemTypeClicked: addItem }));
    }
    if (!items || items.length === 0) {
        return (React.createElement(WithoutItemsContainer, null,
            React.createElement(Message, null, "Utilize este espa\u00E7o para apresentar a obra."),
            renderAddItemButton()));
    }
    return (React.createElement(WithItemsContainer, null,
        React.createElement(Title, null, "Apresenta\u00E7\u00E3o"),
        React.createElement(EditableList, { items: items, onSaved: updateItem, onDelete: removeItem, onOrder: reorderItem, createUploadInstance: createUploadInstance, isUploadComplete: (uploadUrl) => isUploadComplete({ uploadUrl }) }),
        renderAddItemButton()));
};
export default EditableItemList;
